export default [
  {
    title: "My Profile",
    icon: "SettingsIcon",
    children: [
      {
        title: "My Accounts",
        icon: "UserIcon",
        route: "accounts",
        resource: "Invoice",
        action: "manage",
      },
      {
        title: "My Apps",
        icon: "SmartphoneIcon",
        route: "apps",
        resource: "Invoice",
        action: "manage",
      },
    ],
    resource: "Invoice",
    action: "manage",
  },
];
