export default [
  {
    title: "Market Manager",
    icon: "ShoppingBagIcon",
    children: [
      {
        title: "Market Search",
        icon: "SearchIcon",
        route: "market-manager-applications-search",
        resource: "Invoice",
        action: "manage",
      },
      {
        title: "Account Search",
        icon: "SearchIcon",
        route: "market-manager-accounts-search",
        resource: "Invoice",
        action: "manage",
      },
      {
        title: "Favorites Accounts",
        icon: "StarIcon",
        route: "favorite-accounts",
        resource: "Invoice",
        action: "manage",
      },
      {
        title: "Favorites Apps",
        icon: "StarIcon",
        route: "favorites-apps",
        resource: "Invoice",
        action: "manage",
      },
    ],
    resource: "Invoice",
    action: "manage",
  },

];
