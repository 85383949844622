import dashboard from "./admin/dash-admin";
import adminMarketplace from "./marketplace/adminMarketplace";
import invite from "./admin/Invite";
import customVideo from "./admin/custom-video";
export default [
  ...dashboard,
  ...invite,
  ...customVideo,
  ...adminMarketplace
];
