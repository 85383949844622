export default [
  {
    title: "Ads Manager",
    icon: "LayoutIcon",
    children: [
      {
        title: "Campaigns",
        icon: "FileIcon",
        resource: "Invoice",
        action: "manage",
        children: [
          {
            title: "Website Campaign",
            icon: "Link2Icon",
            route: "campaigns",
          },
          {
            title: "Push Campaign",
            route: "push-campaigns",
          },
        ]
      },
      {
        title: "Domains",
        icon: "Link2Icon",
        route: "domains",
        resource: "Invoice",
        action: "manage",
      },
      {
        title: "Configuration",
        icon: "SettingsIcon",
        resource: "Invoice",
        action: "manage",
        children: [
          {
            title: "Main Script",
            route: "configuration-mainscript",
          },
          {
            title: "Template Models",
            route: "configuration-template-models",
          },
          {
            title: "Main Base HTML",
            route: "configuration-main-basehtml",
          },
        ],
      },
    ],
    resource: "Invoice",
    action: "manage",
  },
];
