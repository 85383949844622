export default [
  {
    title: "Push Manager",
    icon: "BellIcon",
    resource: "Invoice",
    action: "manage",
    children: [
      {
        title: "Push Analytics",
        route: "push-dashboard",
        icon: "BarChart2Icon",
      },
      {
        title: "Manage Notifs",
        route: "notifications",
        icon: "ToolIcon",
      },
      {
        title: "Send Notifs V1",
        route: "notifications-sending",
        icon: "SendIcon",
      },
      {
        title: "Send Notif V2",
        route: "notifications-programming",
        icon: "SendIcon",
      },
      {
        title: "Configuration",
        icon: "Edit2Icon",
        resource: "Invoice",
        action: "manage",
        children: [
          {
            title: "App Credentials",
            route: "credentials",
            icon: "Edit2Icon",
          },
          // {
          //   title: "Notis Time",
          //   route: "config-time-notif",
          //   icon: "Edit2Icon",
          // },
        ],
      },
    ],
  },
];
