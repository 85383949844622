export default [
  {
    title: "Assets Manager",
    icon: "FilePlusIcon",
    children: [
      {
        title: "Script Assets",
        icon: "CodeIcon",
        route: "am-assets-source",
        resource: "Invoice",
        action: "manage",
      },
      {
        title: "Data Assets",
        icon: "DatabaseIcon",
        route: "am-assets-data",
        resource: "Invoice",
        action: "manage",
      },
    ],
  },
];
