<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->

    <b-alert
      id="activate-acc"
      show
      dismissible
      variant="warning"
      v-if="verfied"
    >
      Please verify your email address, if you didn't recieve an email ^
      <u> <b id="b-click" @click="ResendMail"> click here </b> </u> ^ to resend
      the email verification
    </b-alert>

    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale /> -->
      <dark-Toggler  />
      <!-- <search-bar /> -->
      <!-- <cart-dropdown /> -->
      <!-- <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BAlert } from "bootstrap-vue";
// import Bookmarks from './components/Bookmarks.vue'
// import Locale from './components/Locale.vue'
// import SearchBar from './components/SearchBar.vue'
import DarkToggler from "./components/DarkToggler.vue";
// import CartDropdown from './components/CartDropdown.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from "./components/UserDropdown.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
export default {
  components: {
    BLink,
    BAlert,
    // Navbar Components
    BNavbarNav,
    // Bookmarks,
    // Locale,
    // SearchBar,
    DarkToggler,
    // CartDropdown,
    // NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      verfied: false,
      token: "",
    };
  },
  created() {
    if (localStorage.getItem("userData")) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      this.verfied = userData.email_verified_at ? false : true;
      this.token = JSON.parse(localStorage.getItem("accessToken"));
    }
  },
  methods: {
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    ResendMail() {
      document.getElementById("activate-acc").style.display = "none";
      this.showToast(
        "success",
        "CheckIcon",
        "Sent !",
        "Mail Resend Successfully !"
      );
      axios.post("/resend-mail-verif", {
        token: this.token,
      });
    },
  },
};
</script>

<style>
#activate-acc {
  position: absolute;
  min-width: 450px !important;
  padding: 5px;
  display: flex;
  align-items: center;
}
#activate-acc > button {
  top: -6px;
  right: -30px;
}
#b-click {
  cursor: pointer;
}
</style>
