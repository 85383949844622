import dashboard from "./dashboard";
import pushManager from "./push-manager";
import marketplace from "./marketplace/marketplace";
import keywordManager from "./keyword-manager";
import settings from "./settings";
import marketManager from "./market-manager";
import topCharts from "./top-charts";
import adsManager from "./ads-manager";
import AssetsManager from "./AssetsManager";
// Array of sections
export default [
  ...dashboard,
  ...adsManager,
  ...pushManager,
  ...marketManager,
  ...keywordManager,
  ...AssetsManager,
  ...marketplace,
  ...topCharts,
  ...settings,
];
